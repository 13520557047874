// import React from 'react';
// import { Link } from "react-router-dom";

const LinkStripComponent = ({ data }) => {
    // const renderLinks = (links) => {
    //     const linkArray = links.split('\n');

    //     return linkArray.map((link, index) => {
    //         const urlMatches = link.match(/href=['"](.*?)['"]/);
    //         const titleMatches = link.match(/>(.*?)<\/a>/);

    //         if (!urlMatches || !titleMatches) {
    //             return null;
    //         }

    //         let url = urlMatches[1];
    //         const title = titleMatches[1];

    //         if (url.includes("dostbeykoz.com")) {
    //             url = url.replace("https://dostbeykoz.com", "");
    //             const pathName = url.split('/').filter((part) => part !== "").pop(); // Son kısmı al
    //             url = `/kategori/${pathName}`;
    //         }
    //         const newUrl = `${url}`;
    //         return (
    //             <Link
    //                 key={index}
    //                 to={newUrl}
    //                 target={newUrl.includes("https://") ? "_blank" : ""}
    //                 rel="noopener noreferrer"
    //                 className="text-white px-2 py-1 hover:bg-gray-700 rounded"
    //             >
    //                 {title}
    //             </Link>
    //         );
    //     });
    // };
    return (
        <div className="bg-gray-900 text-white flex flex-wrap justify-center items-center gap-4" dangerouslySetInnerHTML={{ __html: data }}>
            {/* {data && data.length > 0 && renderLinks(data)} */}
        </div>
    );
};

export default LinkStripComponent;
